import Logo from "../assets/logo-ver2.svg";

export const defaultInstance = {
  name: "Innential",
  mainColor: "#8745EF",
  logo: Logo,
  slug: "Innential",
  mainLanguage: "English",
  followUpQuestionsVersion: false,
  devices: [
    {
      deviceName: "Inverter_1",
      exampleQuestions: [
        "What types of courses pliz offers on machinery safety and automation?",
        "What are the different types of courses pliz offers on machinery safety and automation?",
      ],
    },
    {
      deviceName: "Battery",
      exampleQuestions: ["What types of batteries are safe to use?"],
    },
    { deviceName: "Solar Panel", exampleQuestions: [] },
  ],
  deviceTypes: [
    {
      type: "Heat Pump",
      manufacturers: [
        {
          manufacturer: "SMA",
          companyName: "SMA-1",
          devices: [
            {
              deviceName: "Model30",
              exampleQuestions: [
                "What types of courses pliz offers on machinery safety and automation?",
                "What are the different types of courses pliz offers on machinery safety and automation?",
              ],
            },
            {
              deviceName: "Model4",
              exampleQuestions: [
                "What types of courses pliz offers on machinery safety and automation?",
              ],
            },
            {
              deviceName: "Model5",
              exampleQuestions: [],
            },
            {
              deviceName: "Model12",
              exampleQuestions: [
                "What types of courses pliz offers on machinery safety and automation?",
                "What are the different types of courses pliz offers on machinery safety and automation?",
              ],
            },
            {
              deviceName: "Model11",
              exampleQuestions: [
                "What types of courses pliz offers on machinery safety and automation?",
                "What are the different types of courses pliz offers on machinery safety and automation?",
              ],
            },
            {
              deviceName: "Model8",
              exampleQuestions: [],
            },
            {
              deviceName: "Model9",
              exampleQuestions: [],
            },
            {
              deviceName: "Model10",
              exampleQuestions: [],
            },
          ],
        },
        {
          manufacturer: "Zolar",
          companyName: "Zolar-1",
          devices: [
            {
              deviceName: "Model1",
              exampleQuestions: [
                "What types of courses pliz offers on machinery safety and automation?",
              ],
            },
            {
              deviceName: "Model3",
              exampleQuestions: [
                "What are the different types of courses pliz offers on machinery safety and automation?",
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Solar Panel",
      manufacturers: [
        {
          manufacturer: "SMA",
          companyName: "SMA-12",
          devices: [
            {
              deviceName: "Model4",
              exampleQuestions: [],
            },
            {
              deviceName: "Model5",
              exampleQuestions: [],
            },
          ],
        },
      ],
    },
  ],
  categoriesContainer: {
    label: "My Devices",
    categories: [
      {
        name: "Inverter_1",
        devices: [
          {
            deviceName: "Model30",
            exampleQuestions: [
              "What types of courses pliz offers on machinery safety and automation?",
              "What are the different types of courses pliz offers on machinery safety and automation?",
            ],
          },
          {
            deviceName: "Model4",
            exampleQuestions: [
              "What types of courses pliz offers on machinery safety and automation?",
            ],
          },
          {
            deviceName: "Model5",
            exampleQuestions: [],
          },
          {
            deviceName: "Model12",
            exampleQuestions: [
              "What types of courses pliz offers on machinery safety and automation?",
              "What are the different types of courses pliz offers on machinery safety and automation?",
            ],
          },
          {
            deviceName: "Model11",
            exampleQuestions: [
              "What types of courses pliz offers on machinery safety and automation?",
              "What are the different types of courses pliz offers on machinery safety and automation?",
            ],
          },
          {
            deviceName: "Model8",
            exampleQuestions: [],
          },
          {
            deviceName: "Model9",
            exampleQuestions: [],
          },
          {
            deviceName: "Model10",
            exampleQuestions: [],
          },
        ],
      },
      {
        name: "Inverter_2",
        devices: [
          {
            deviceName: "Model1",
            exampleQuestions: [
              "What types of courses pliz offers on machinery safety and automation?",
            ],
          },
        ],
      },
    ],
  },
  type: "Manufacturer",
  // type: "Distributor",
  // type: "Advanced Manufacturer",
  aiTrained: false,
  typeOfDemo: "Both",
  newAi: true,
  customFallbackMessage: "",
  faq: [],
};
