import { AppContext } from "../context";
import { useUIText } from "../hooks";
import React, { useContext, useState } from "react";
import { formatDeviceName } from "../utils";
import ReactSelect from "react-select";
import { SubmitButton } from "./NewFlowConversationContainer";
import ShowIf from "./ShowIf";
import AlertTriangle from "../assets/alert-triangle.svg";
export default function AdvancedManufacturerSelector({
  selectedDevice,
  setSelectedDevice,
  disabled,
  handleSubmittingDevice,
  classification,
  submitDisabled = false,
}) {
  const uiText = useUIText();
  const { instanceInfo } = useContext(AppContext);
  const [selectedType, setSelectedType] = useState(null);
  const types = React.useMemo(() => {
    return (
      instanceInfo?.categoriesContainer?.categories?.map((category) => {
        return {
          value: category.name,
          label:
            uiText?.deviceTypes?.[category.name?.toLowerCase()] ||
            category.name,
        };
      }) ?? []
    );
  }, [instanceInfo, uiText]);
  React.useEffect(() => {
    if (classification) {
      const deviceType = instanceInfo?.categoriesContainer?.categories?.find(
        (category) => {
          return category?.files?.some((file) => {
            return file?.devices?.some((device) => {
              return (
                formatDeviceName(device.deviceName) === classification?.device
              );
            });
          });
        }
      );
      if (deviceType) {
        const deviceInfo = deviceType?.files?.reduce((acc, curr) => {
          if (acc) return acc;
          return curr.devices.find((device) => {
            return (
              formatDeviceName(device.deviceName) ===
              String(classification?.device)
            );
          });
        }, null);
        if (deviceInfo) {
          setSelectedType({
            value: deviceType.name,
            label: deviceType.name,
          });
          setSelectedDevice({
            deviceName: deviceInfo.deviceName,
            manufacturer: null,
            deviceType: deviceType.name,
          });
        }
      }
    }
  }, [
    classification,
    instanceInfo,
    setSelectedType,
    setSelectedDevice,
    uiText,
  ]);
  const devices = React.useMemo(() => {
    return (
      instanceInfo?.categoriesContainer?.categories
        ?.find((category) => {
          return category.name === selectedType?.value;
        })
        ?.files?.reduce((acc, curr) => {
          return Array.from(
            new Set([
              ...acc,
              ...curr.devices.map((device) => device.deviceName),
            ])
          );
        }, [])
        ?.map((device) => {
          return {
            value: device,
            label: formatDeviceName(device),
          };
        }) ?? []
    );
  }, [instanceInfo, selectedType]);

  return (
    <div
      className={`selection-wrapper`}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <div className="new-selection-container" >
        <div className="customer-selection">
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            options={types}
            placeholder={uiText?.form?.deviceTypePlaceholder}
            value={selectedType}
            onChange={(value) => {
              setSelectedType(value);
              setSelectedDevice(null);
            }}
            menuPlacement="top"
          />
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            options={devices}
            placeholder={
              devices.length === 0
                ? uiText?.form?.manufacturerPlaceholder1
                : uiText?.form?.deviceModelPlaceholder2
            }
            value={
              selectedDevice
                ? {
                    value: selectedDevice.deviceName,
                    label: formatDeviceName(selectedDevice.deviceName),
                  }
                : null
            }
            onChange={(value) => {
              setSelectedDevice({
                deviceName: value?.value || "",
                manufacturer: null,
                deviceType: selectedType?.value || "",
              });
            }}
            isDisabled={devices.length === 0}
            menuPlacement="top"
          />
        </div>
        <SubmitButton
          disabled={submitDisabled}
          onClick={() => {
            handleSubmittingDevice(selectedDevice);
          }}
        />
      </div>

      {/* <ShowIf condition={!selectedDevice}>
        <div className="device-select-warning">
          <img
            alt="alert"
            src={AlertTriangle}
            width={"15.26px"}
            height={"13.4px"}
          />
          <span>{uiText?.form?.selectDeviceWarning}</span>
        </div>
      </ShowIf> */}
    </div>
  );
}
