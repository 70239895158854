/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { formatDeviceName } from "../utils";
import { useUIText } from "../hooks";

const AutoSelectManufacturerAdvCustomer = ({
  items,
  userInput,
  setUserInput,
  setExampleQuestions,
  reset,
  setReset,
  handleSetShowFaqs,
  setRelatedDevices,
  extraClassName = "",
}) => {
  const uiText = useUIText();
  const [devices, setDevices] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const types = useMemo(() => {
    return items?.categories.map((category) => ({
      value: category.name,
      label:
        uiText?.deviceTypes?.[category.name?.toLowerCase()] || category.name,
    }));
  }, [items, uiText]);

  useEffect(() => {
    if (selectedType) {
      const selectedCategory = items?.categories.find(
        (category) => category.name === selectedType.value
      );
      const devices =
        selectedCategory?.devices?.map((item) => ({
          value: item.deviceName,
          label: formatDeviceName(item.deviceName),
        })) || [];
      setDevices(devices);
      setSelectedDevice(null); // Reset selected device when type changes
      setUserInput({ ...userInput, device: "" });
      setExampleQuestions([]);
    } else {
      setDevices([]);
      setSelectedDevice(null);
      setExampleQuestions([]);
    }
  }, [items, selectedType]);

  useEffect(() => {
    if (selectedType) {
      setSelectedType({
        value: selectedType.value,
        label:
          uiText?.deviceTypes?.[selectedType.value?.toLowerCase()] ||
          selectedType.value,
      });
    }
  }, [uiText]);

  useEffect(() => {
    if (reset) {
      setSelectedType(null);
      setSelectedDevice(null);
      setExampleQuestions([]);
      setReset(false);
    }
  }, [reset]);

  return (
    <div className={`selection-wrapper ${extraClassName}`}>
      <div className="selection-title">
        {!selectedDevice ? uiText.form.selectDevice : uiText.form.yourDevice}
      </div>
      <div className="selection-container" style={{ paddingTop: "4px" }}>
        <div className="customer-selection">
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            options={types}
            placeholder={uiText?.form?.deviceTypePlaceholder}
            value={selectedType}
            onChange={(value) => {
              setSelectedType(value);
              setUserInput({
                ...userInput,
                device: "",
                problem: "",
                deviceType: value?.value || "",
              });
            }}
            menuPlacement="top"
          />
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            options={devices}
            placeholder={
              devices.length === 0
                ? uiText?.form?.manufacturerPlaceholder1
                : uiText?.form?.deviceModelPlaceholder2
            }
            value={selectedDevice}
            onChange={(value) => {
              setSelectedDevice(value);
              const selectedCategory = items.categories.find(
                (category) => category.name === selectedType.value
              );
              const selectedDevice = selectedCategory.devices.find(
                (device) => device.deviceName === value.value
              );
              setUserInput({
                ...userInput,
                device: value?.value || "",
                problem: "",
              });
              setExampleQuestions(selectedDevice.exampleQuestions);
              setRelatedDevices(selectedDevice.relatedDevices);
              if (selectedDevice?.exampleQuestions?.length > 0) {
                handleSetShowFaqs(false);
                setExampleQuestions(selectedDevice.exampleQuestions || []);
              }
            }}
            isDisabled={devices.length === 0}
            menuPlacement="top"
          />
        </div>
      </div>
    </div>
  );
};

export default AutoSelectManufacturerAdvCustomer;
