import React from "react";
import PropTypes from "prop-types";
import FullAccessWelcome from "./full-access/FullAccessWelcome";
import NewFlowConversationContainer from "./NewFlowConversationContainer";
export default function NewFlow(params) {
  const { variables } = params;
  const [showFullAccess, setShowFullAccess] = React.useState(
    variables?.limitReached ?? false
  );
  React.useEffect(() => {
    if (variables?.limitReached) {
      setShowFullAccess(true);
    }
  }, [variables.limitReached, setShowFullAccess]);
  return (
    <div>
      <div className={`customer-layout ${showFullAccess ? "blurred" : ""}`}>
        <NewFlowConversationContainer {...params} />
      </div>
      {showFullAccess && (
        <div className="full-access-overlay">
          <FullAccessWelcome />
        </div>
      )}
    </div>
  );
}

NewFlow.propTypes = {
  logo: PropTypes.string,
  typeOfDemo: PropTypes.string,
  handleViewChange: PropTypes.func,
  variables: PropTypes.object,
  feedbackIsAdded: PropTypes.bool,
  setFeedbackIsAdded: PropTypes.func,
  isTesting: PropTypes.bool,
  mainColor: PropTypes.string,
};
